import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Card,
	Center,
	Checkbox,
	Flex,
	Image,
	Input,
	InputGroup,
	InputRightAddon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tag,
	Text,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsCheck2, BsPatchCheckFill, BsPerson, BsSearch } from "react-icons/bs"
import { Profile } from "src/domain/entities/profile"
import { Registration } from "src/domain/entities/registration"
import { useAuth } from "src/domain/hooks"
import { useProfileListService } from "src/domain/hooks/useProfileListService"
import { registrationProfileLinkService } from "src/domain/services/registration/registrationProfileLinkService"
import {
	fetchGujaratiSuggestions,
	getErrorMessage,
	getVillageName,
} from "src/utils/helpers"

import { debounce } from "lodash"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { Language } from "src/utils/enums"
import { ProfileDetails } from "../../ProfileDetailsModal"
import { registrationProfileUnlinkService } from "src/domain/services/registration/registrationProfileUninkService"
import { ImageViewModalController } from "src/components/ui/ImageViewModalController"

interface Props {
	registration: Registration
	linkedProfile: Profile | null
	onApproveReview: () => void
	setLinkedProfile: (profile: Profile | null) => void
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const LinkProfileModalController: FC<Props> = ({
	isOpen,
	setIsOpen,
	onApproveReview,
	registration,
	linkedProfile,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLinkLoading, setIsLinkLoading] = useState(false)
	const [isUnLinkLoading, setIsUnLinkLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [selectedProfile, setSelectedProfile] = useState<Profile | null>(linkedProfile)

	const [selectedViewImageUrl, setSelectedViewImageUrl] = useState<string | null>(null)

	const [hasAcceptedNoLinkableProfile, setHasAcceptedNoLinkableProfile] =
		useState(false)

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const searchText = useRef(
		linkedProfile
			? linkedProfile.firstName?.en + " " + linkedProfile.surname?.name.en
			: registration.basic?.firstName?.en ?? "",
	)
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const {
		profileList,
		isLoading: isProfileListLoading,
		fetchProfileList,
	} = useProfileListService()

	const handleLinkProfile = useCallback(async () => {
		try {
			setIsLinkLoading(true)
			if (!selectedProfile) return

			await registrationProfileLinkService(
				{
					registrationId: registration.id,
					linkProfileId: selectedProfile.id,
				},
				token,
			)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLinkLoading(false)
		}
	}, [registration, token, onSuccess, selectedProfile])

	const handleUnlinkProfile = useCallback(async () => {
		try {
			setIsUnLinkLoading(true)
			await registrationProfileUnlinkService(
				{
					registrationId: registration.id,
				},
				token,
			)

			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsUnLinkLoading(false)
		}
	}, [registration, token, onSuccess])

	const fetchProfiles = useCallback(async () => {
		await fetchProfileList({
			fetch: {
				surname: {
					subCaste: true,
				},
				nativeVillage: {
					taluka: true,
					district: true,
					state: true,
				},
				currentVillage: {
					taluka: true,
					district: true,
					state: true,
					country: true,
				},
			},
			search: searchText.current,
			subCasteId:
				registration.basic?.surname?.subCaste?.id ??
				registration.basic?.customSurname?.subCaste?.id,
			surnameId: registration.basic?.surname?.id,
			gender: registration.basic?.gender,
			isAlive: true,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchProfileList, pagination.currentPage, registration])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchProfiles = useCallback(
		debounce(async () => {
			await fetchProfiles()
		}, 500),
		[fetchProfiles],
	)

	useEffect(() => {
		fetchProfiles()
	}, [fetchProfiles])

	function getAvatar(profile: Profile) {
		return profile.profileImageUrl ? (
			<Image
				style={{ aspectRatio: "1" }}
				src={profile.profileImageUrl}
				height="50px"
				rounded={"lg"}
				onClick={(e) => {
					e.stopPropagation()
					setSelectedViewImageUrl(profile.profileImageUrl!)
				}}
			/>
		) : (
			<Flex
				height={50}
				width={50}
				bgColor="gray.100"
				rounded={"lg"}
				justify="center"
				align={"center"}
				color="gray.500"
			>
				<BsPerson size={20} />
			</Flex>
		)
	}

	function getUserName(profile: Profile) {
		return (
			<>
				<Text fontWeight={500}>
					<Flex align={"center"}>
						<Text>
							{profile.firstName?.en} {profile.middleName?.en}{" "}
							{profile.surname ? profile.surname?.name.en : null}
						</Text>
						{profile.isVerified ? (
							<Text ml={2} color="blue.400">
								<BsPatchCheckFill size={16} />
							</Text>
						) : null}
					</Flex>
				</Text>
				<Text>
					{profile.firstName?.gu} {profile.middleName?.gu}{" "}
					{profile.surname ? profile.surname?.name.gu : null}
				</Text>
			</>
		)
	}

	function getNativeVillage(profile: Profile) {
		return (
			<>
				{" "}
				<Text>
					{getVillageName(profile.nativeVillage, {
						depth: 3,
					})}
				</Text>
				<Text fontSize={{ base: "sm", lg: "md" }}>
					{getVillageName(profile.nativeVillage, {
						lang: Language.GUJARATI,
						depth: 3,
					})}
				</Text>
			</>
		)
	}

	function getCurrentVillage(profile: Profile) {
		return (
			<>
				<Text>
					{getVillageName(profile.currentVillage, {
						depth: 3,
					})}
				</Text>
				<Text fontSize={{ base: "sm", lg: "md" }}>
					{getVillageName(profile.currentVillage, {
						lang: Language.GUJARATI,
						depth: 3,
					})}
				</Text>
			</>
		)
	}

	function getActionButtons() {
		return (
			<Box flex={1}>
				<Flex flex={1}>
					<Checkbox
						isChecked={hasAcceptedNoLinkableProfile}
						onChange={(e) => {
							if (e.target.checked) {
								setHasAcceptedNoLinkableProfile(true)
							} else {
								setHasAcceptedNoLinkableProfile(false)
							}
						}}
						pb={4}
					>
						<Text fontSize={"sm"} color={"gray.500"} px={1}>
							No profiles found with linkable information.
						</Text>
					</Checkbox>
				</Flex>
				<Flex flex={1} justify={"space-between"}>
					<Flex gridColumnGap={2}>
						{!registration.linkedProfileId ? (
							<Button
								colorScheme={"blue"}
								isLoading={isLinkLoading}
								onClick={handleLinkProfile}
								disabled={!selectedProfile}
							>
								{selectedProfile
									? `Link ${registration.basic?.firstName.en} to ${selectedProfile?.firstName?.en}`
									: "Save"}
							</Button>
						) : (
							<Button
								colorScheme={"red"}
								isLoading={isUnLinkLoading}
								onClick={handleUnlinkProfile}
								disabled={!selectedProfile}
							>
								{selectedProfile
									? `Unlink ${registration.basic?.firstName.en} from ${selectedProfile?.firstName?.en}`
									: "Save"}
							</Button>
						)}
					</Flex>
					<Flex gridColumnGap={2}>
						<Button
							colorScheme={"green"}
							rightIcon={<BsCheck2 />}
							disabled={
								!hasAcceptedNoLinkableProfile &&
								!registration.linkedProfileId
							}
							onClick={onApproveReview}
						>
							Approve
						</Button>
					</Flex>
				</Flex>
			</Box>
		)
	}

	function getSearchFilter() {
		return (
			<Flex
				gridColumnGap={2}
				align="center"
				direction={{ base: "column", lg: "row" }}
			>
				<Box display={{ base: "flex", lg: "block" }}>
					<InputGroup width={"fit-content"}>
						<Input
							value={searchText.current}
							onChange={(e) => {
								if (pagination.currentPage !== 1)
									pagination.setCurrentPage(1)
								searchText.current = e.target.value
								debouncedFetchProfiles()
								getGujaratiSuggestions(e.target.value)
							}}
							type="text"
							placeholder="Search"
						/>
						<InputRightAddon>
							<BsSearch />
						</InputRightAddon>
					</InputGroup>
				</Box>
				<Box>
					{gujaratiSuggestions.map((el, i) => (
						<Tag
							key={i}
							colorScheme={"green"}
							backgroundColor={"green.50"}
							variant="outline"
							_hover={{
								backgroundColor: "green.100",
							}}
							cursor="pointer"
							margin={0.5}
							onClick={() => {
								pagination.setCurrentPage(1)
								searchText.current = el
								debouncedFetchProfiles()
								setGujaratiSuggestions([])
							}}
						>
							{el}
						</Tag>
					))}
				</Box>
				{isProfileListLoading ? <CenteredSpinner /> : null}
			</Flex>
		)
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Link Profile</ModalHeader>
					<ModalCloseButton />
					<ModalBody display={{ base: "none", lg: "flex" }}>
						<Box flex={1}>
							<Box padding={2}>
								<Flex justifyContent="space-between" alignItems="center">
									<Text fontSize="2xl" fontWeight="bold">
										Profiles
									</Text>
								</Flex>
							</Box>
							<Box px={2} mb={4}>
								{getSearchFilter()}
							</Box>
							{profileList.map((profile, i) => (
								<Card
									key={i}
									display={"flex"}
									m={"3"}
									style={{ cursor: "pointer" }}
									_hover={{ bg: "gray.100" }}
									onClick={() => {
										setSelectedProfile(profile)
									}}
									border={"1px solid #e2e8f0"}
									shadow={"none"}
								>
									<Flex
										justify={"space-between"}
										direction={"row-reverse"}
									>
										<Box m={"3"}>
											{selectedProfile?.id === profile.id ? (
												<BsCheck2 size={"20"} />
											) : null}
										</Box>
										<Box m={"2"}>
											<Flex direction={"column"}>
												<Flex>
													<Box>{getAvatar(profile)}</Box>
													<Box ml={"2"}>
														{getUserName(profile)}
													</Box>
												</Flex>
												<Flex direction={"column"} my={"2"}>
													<Box fontWeight={"semibold"}>
														Native Village:
													</Box>
													<Box>{getNativeVillage(profile)}</Box>
												</Flex>
												<hr style={{ margin: "4px 0" }} />
												<Flex direction={"column"}>
													<Box fontWeight={"semibold"}>
														Current Village:
													</Box>
													<Box>
														{getCurrentVillage(profile)}
													</Box>
												</Flex>
											</Flex>
										</Box>
									</Flex>
								</Card>
							))}
							<CustomPagination
								pagination={pagination}
								isNextDisabled={profileList.length === 0}
							/>

							{errorMessage}
						</Box>
						{selectedProfile ? (
							<Box flex={1}>
								<ProfileDetails
									profile={selectedProfile}
									handleProfileClick={(profile) =>
										setSelectedProfile(profile)
									}
								/>
							</Box>
						) : null}
					</ModalBody>

					<Box>
						<Flex justify={"space-around"}>
							<Box display={{ base: "flex", lg: "none" }}>
								{getSearchFilter()}
							</Box>
						</Flex>
						{profileList.length === 0 && !isProfileListLoading ? (
							<Center display={{ base: "flex", lg: "none" }}>
								<Text>No Profiles Found</Text>
							</Center>
						) : null}

						<Flex
							display={{ base: "flex", lg: "none" }}
							direction={{ base: "column" }}
						>
							<Box my={"4"} mx={"9"}>
								{getActionButtons()}
							</Box>
							<Box mb={"3"}>
								<CustomPagination
									pagination={pagination}
									isNextDisabled={profileList.length === 0}
								/>

								{errorMessage}
							</Box>
						</Flex>
					</Box>
					<ModalFooter display={{ base: "none", lg: "flex" }} pt={0}>
						{getActionButtons()}
					</ModalFooter>
				</ModalContent>
			</Modal>
			{selectedViewImageUrl != null ? (
				<ImageViewModalController
					isOpen={selectedViewImageUrl != null}
					setIsOpen={() => setSelectedViewImageUrl(null)}
					image={
						<Image
							src={selectedViewImageUrl}
							alt="profile"
							objectFit="contain"
							maxH="90vh"
							maxW="90vw"
						/>
					}
				/>
			) : null}
		</>
	)
}
